import { defineStore } from 'pinia'
import { io } from 'socket.io-client'

export const useSocketStore = defineStore('socketStore', {
  state: () => ({
    socket: null as any,
    isConnected: false,
  }),
  actions: {
    setSocket(token: string) {
      const { SOCKET_URL } = useRuntimeConfig().public
      this.socket = io(SOCKET_URL, {
        extraHeaders: {
          authorization: 'Bearer ' + token,
        },
      })
      this.socket.on('connect', () => {
        this.isConnected = true
        console.log('Socket Connected...')
      })

      this.socket.on('disconnect', () => {
        this.isConnected = false
        console.log('Socket Disconnected...')
      })
    },
  },
})
